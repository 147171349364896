.content-item {
    display: block;
}

.content-item_white {
    color: white;
}

.content-item__text {    
    margin: 15px 0 15px 0;
    padding: 0;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.16;
    letter-spacing: -0.04em;
}

.content-item__title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 30px;
    line-height: 1.16;
}

@media screen and (max-width: 1279px) {
.content-item__text {
    font-size: 16px;
}

.content-item__title {
    font-size: 24px;
}
}

@media screen and (max-width: 767px) {
    .content-item__text {
        font-size: 12px;
        line-height: 1.4;
    }
    
    .content-item__title {
        font-size: 20px;
        line-height: 1.4;
    }
}