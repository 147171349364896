.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 100px;
}

.main_promo {
  background-image: url('../../images/9.jpg');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.main_promo::after {
  content: '';
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%; 
  top: 0;
  left: 0;
  background: black;
  opacity: 0.5;
}

.main_advantages {
  background-image: url('../../images/work-sample-4.JPG');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 40vh;
}

.main_advantages::after {
  content: '';
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.3;
}

.main_clients {
  background-image: url('../../images/work-sample-3.JPG');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 40vh;
}

.main_clients::after {
  content: '';
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.4;
}

.main-page {
  width: min(800px, 100%);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 150px 1fr;
  justify-items: center;
  position: relative;
  z-index: 3;
}

.main-page__item {
  z-index: 4;
}

.main-content__title {
  margin: 25px 0 0 0;
  padding: 0;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  color: white;
}


.main-content__title_black {
  color: black;
}

.main__nav-tab {
  position: fixed;
  left: 50px;
  top: 50px;
  background-color: black;
  width: 200px;
}

.main__title-image {
  display: flex;
  justify-content: center;
  align-items: center;  
  width: min(400px, 80%);
}

.main__nav-burger {
  position: fixed;
  padding: 5px;
  right: 5px;
  top: 10px;
  background-color: grey;
  width: 30px;
  height: 30px;
  background-image: url('../../images/burger-icon.svg');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 4;
  border: none;
  border-radius: 50%;
}

@media screen and (max-width: 1279px) {
  .main-page {
    width: 100%;
  }
}

@media screen and (max-width: 1279px) {
  .main {
    padding-bottom: 50px;
  }

  .main-page {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    grid-template-rows: min-content 1fr;
    align-content: center;
  }
  
  .main-page_promo {
    grid-template-rows: 150px 150px 1fr;
  }

  .main-content__title {
    font-size: 60px;
  }

  .main-page_promo {
    grid-template-rows: min-content min-content 1fr;
  }
}

  @media screen and (max-width: 767px) {
    .main {
      padding-bottom: 20px;
    }
  
    .main-page {
      padding-left: 20px;
      padding-right: 20px;
    }

    .main-content__title {
      font-size: 36px;
    }
}    