.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 30px;
  box-sizing: border-box;
}

.header__main-logo {
  width: 300px;
}

.header__account-logo {
  width: 100px;
  height: 38px;
}

.header__contacts {
  display: flex;
  flex-direction: column;
}

.header__links-block {
  margin-top: 5 px;
  display: flex;
}

.header__link {
  margin-left: 10px;
  color: black;
  text-decoration: none;
  cursor: pointer;


  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: white;
  text-align: right;
}

.header__text {
  margin: 0;
  padding: 0;
  color: black;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: 1.5;
  color: white;
  text-align: right;
}


.header__burger {
  display: none;
  border: none;
  background-image: url('../../images/burger-icon.svg');
  background-repeat: no-repeat;
  width: 40px;
  height: 43px;
  background-color: white;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.header__whatsapp-icon {
  height: 25px;
}

@media screen and (max-width: 1279px) {
  .header__link {
    font-size: 16px;
  }

  .header__text {
    font-size: 20px;
  }

  .header__whatsapp-icon {
    height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .header__link {
    font-size: 14px;
  }

  .header__text {
    font-size: 18px;
  }

  .header__whatsapp-icon {
    height: 18px;
  }
}