.contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.contacts__item {
    max-width: 1200px;
    margin: 20px 0 0 0;
    padding: 0;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.16;
    text-align: left;
    letter-spacing: -0.04em;
    color: black;
}

.contacts__item_link {
    text-decoration: none;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.16;
}

.contacts__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.contacts__list-item {
    margin: 10px 40px 0 40px;
    padding: 0;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.16;
    letter-spacing: -0.04em;
}

.contacts__list-item_title {
    margin-top: 30px;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.16;
}

.contacts__highlight-text {
    margin-top: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.16;
    text-decoration: underline;
}

.contacts__feedback-title {
    margin: 30px 0 0 0;
    padding: 0;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 1.16;
    line-height: 1.16;
    letter-spacing: -0.04em;
}

.contacts__feedback-block {
    margin: 20px 0 0 0;
    display: grid;
    grid-template-columns: auto auto;
    gap: 60px;
}

.contacts__feedback-link {
    margin: 0;
    padding: 10px;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.16;
    letter-spacing: -0.04em;
    border: 2px solid black;
    width: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    color: black;
}

.contacts__feedback-image {
    width: 100px;
    height: 38.5px;
}

.contacts__support-block {
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contacts__support-item {
    padding: 0;
    margin: 5px 0 0 0;
}

@media screen and (max-width: 1279px) {
    .contacts {
        margin-bottom: 50px;
    }

    .contacts__item {
        font-size: 18px;
    }

    .contacts__feedback-title {
        font-size: 24px;
    }

    .contacts__item_link {
        font-size: 18px;
    }

    .contacts__highlight-text {
        font-size: 20px;
    }    
}

@media screen and (max-width: 767px) {
    .contacts {
        margin-bottom: 20px;
    }

    .contacts__item {
        font-size: 14px;
    }

    .contacts__feedback-title {
        font-size: 24px;
    }

    .contacts__highlight-text {
        font-size: 16px;
    }    

    .contacts__list-item_link {
        font-size: 14px;
    }
    
    .contacts__feedback-block {
        grid-template-columns: none;
        grid-template-rows: auto auto;
        gap: 20px;
    }
}    