.promo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  background: none;
}

.promo__text {
  max-width: 1200px;
  margin: 80px 0 0 0;
  padding: 0;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 1.16;
  text-align: center;
  letter-spacing: -0.04em;
  color:white;
}

.promo__video-wrapper {
  margin-top: 100px;
  position: relative;
  width: 100%;
  padding-bottom: 80%; /* 16:9 */
  height: 0;
}

.promo__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1279px) {
  .promo__text {
    margin: 80px 0 0 0;
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .promo__text {
    margin: 50px 0 0 0;
    font-size: 20px;
  }

  .promo__video-wrapper {
    margin-top: 50px;
  }  
}    