.navigation {
  display: none;
  position: fixed;
  right: 0;
  top: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,0.5); /*dim the background*/
}

.navigation_opened {
  display: flex;
}
.navigation__internal-content {
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: min(100%, 320px);


  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003366;
}

.navigation__links-block {
  margin-top: 159px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation__close-button {
  position: absolute;
  right: 14px;
  top: 14px;
  border: none;
  background-image: url('../../images/close-button.svg');
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-color: #003366;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.navigation__link {
  margin-bottom: 2px;
  margin-top: 24px;
  color: white;
  background-color: #003366;
  border: none;
  cursor: pointer;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  text-align: center;
}

.navigation__link:hover{
  margin-bottom: 0;
  border-bottom: 2px black solid;
}

.navigation__logo {
  width: 200px;

}